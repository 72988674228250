import { LawTheme, TreeWithId } from '@livv/models';
import { Box, Grid, SxProps, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { BooksPageProps } from '@screens/Books/Page';
import { LawThemeCard } from '@screens/Home/LawThemeCard';
import { THEME_ORDER } from '@utils/consts/trees';
import { buildThemesFromIsKeywordBooks } from '@utils/trees';

const titleSx: SxProps = {
    color: 'grey.800',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.625,
    marginBottom: 0.5,
};

export const LawThemesContainer: FC<BooksPageProps> = ({ books }) => {
    const { t } = useTranslation('home');
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'), {
        defaultMatches: false,
    });
    const treesByThemes: Record<LawTheme, TreeWithId[]> = buildThemesFromIsKeywordBooks(books);

    const renderLawThemeCard = (lawThemes: LawTheme[]) =>
        lawThemes
            .filter((lawTheme) => treesByThemes[lawTheme].length > 0)
            .map((lawTheme) => (
                <LawThemeCard key={lawTheme} books={treesByThemes[lawTheme]} lawTheme={lawTheme} />
            ));

    return (
        <Box data-test-id="law-theme-container" sx={{ my: 10, width: 0.9 }}>
            <Typography data-test-id="law-theme-container-title" sx={titleSx} variant="h4">
                {t('lawThemeContainer.title')}
            </Typography>
            <Typography data-test-id="law-theme-container-description" sx={{ color: 'grey.700' }}>
                {t('lawThemeContainer.description')}
            </Typography>

            <Grid
                display="grid"
                gap={2}
                gridTemplateColumns={isTablet ? '1fr' : '1fr 1fr'}
                marginTop={2}
                container
            >
                <Grid
                    data-test-id="law-theme-card-left"
                    display="grid"
                    gap={2}
                    gridAutoRows="min-content"
                >
                    {renderLawThemeCard(THEME_ORDER.slice(0, 1))}
                </Grid>

                <Grid
                    data-test-id="law-theme-card-right"
                    display="grid"
                    gap={2}
                    gridAutoRows="min-content"
                >
                    {renderLawThemeCard(THEME_ORDER.slice(1))}
                </Grid>
            </Grid>
        </Box>
    );
};
